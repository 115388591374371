<template>
  <div class="joinUs">
    <div class="banner-wrapper">
      <img src="../assets/join_us/banner.png" alt="">
    </div>
    <div class="jobs-wrapper">
      <ul class="jobsList">
        <li v-for="job in jobsList" :key="job.id">
          <h3 class="title">{{ job.title }}</h3>
          <span class="subTitle">{{ job.subTitle }}</span>
          <p v-for="item in job.textList" :key="item">{{ item }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'joinUs',
    data(){
      return {
        jobsList: [
          {
            id: 1,
            title: '运营专员',
            subTitle: '青岛 | 运营 | 2人',
            textList: [
              '1、统招专科及以上学历',
              '2、对网约车业务有一定认知，良好的职业道德',
              '和出色的沟通能力。有一定数据分析能力，',
              '判断能力'
            ],
          },
          {
            id: 2,
            title: '客服专员',
            subTitle: '张家口 | 客服 | 5人',
            textList: [
              '1、有相应线上售后客服的管理经验。',
              '2、技能：对网约车有一定的认知，良好的沟通能力、',
              '应变能力和亲和力，声音甜美。',
            ],
          },
          {
            id: 3,
            title: '质检专员',
            subTitle: '青岛 | 质检 | 2人',
            textList: [
              '1、学历要求：本科及以上学历',
              '2、技能：有良好的职业道德和出色的沟通能力；',
              '具有较强的判断、理解、分析和学习能力；',
              '较强的执行、协作能力',
            ],
          },
          {
            id: 4,
            title: '后端工程师',
            subTitle: '青岛 | 技术 | 1人',
            textList: [
              '1、本科及以上学历',
              '2、精通golang，有独立后端开发经验者优先',
            ],
          },
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
.joinUs {
  > .banner-wrapper {
    width: 100%;
    > img {
      width: 100%;
      vertical-align: bottom;
    }
  }
  > .jobs-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    background-color: #f7f7f7;
    > .jobsList {
      width: 1146px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      > li {
        width: 548px;
        text-align: left;
        border-radius: 4px;
        box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.2);
        margin-bottom: 48px;
        padding: 30px 30px 50px 40px;
        background-color: #fff;
        > .title {
          font-size: 32px;
        }
        > .title::after {
          display: block;
          content: '';
          height: 4px;
          width: 140px;
          background-color: #ffc233;
          margin-top: 10px;
          margin-bottom: 20px;
        }
        > .subTitle {
          display: inline-block;
          font-size: 20px;
          margin-bottom: 40px;
        }
        > p {
          font-size: 20px;
          color: #7a7a7a;
        } 
        > p:nth-child(5),
        > p:nth-child(6) {
          text-indent: 1.5em;
        }
      }
      > li:nth-child(odd) {
        margin-right: 50px;
      }
    }
  }
}
</style>